export const SvgName = () => (
  <svg
    className="svg-loading" // Assigns a CSS class to the SVG for styling.
    width="900" // Sets the width of the SVG element to 900 pixels.
    height="100" // Sets the height of the SVG element to 100 pixels.
    viewBox="0 0 920 100" // Defines the position and dimension in user space which should be mapped to fit into the viewport defined by the width and height attributes. Here, it starts at (0,0) and extends to 920 units in width and 100 units in height.
    fill="none" // Sets the fill color of the SVG. "none" means that no fill is applied.
    preserveAspectRatio="xMidYMid meet" // Controls how the aspect ratio of the SVG content is preserved. "xMidYMid" means it's centered both horizontally and vertically. "meet" ensures that the entire viewBox is visible within the viewport even if that means leaving some whitespace.
    xmlns="http://www.w3.org/2000/svg" // Defines the XML namespace for the SVG. This is necessary for the SVG to be correctly interpreted.
  >
    <g id="svg-name">
      <g id="E">
        <circle id="1" cx="2" cy="2" r="2" fill="black" />
        <circle id="2" cx="7" cy="2" r="2" fill="black" />
        <circle id="3" cx="12" cy="2" r="2" fill="black" />
        <circle id="4" cx="17" cy="2" r="2" fill="black" />
        <circle id="5" cx="22" cy="2" r="2" fill="black" />
        <circle id="6" cx="27" cy="2" r="2" fill="black" />
        <circle id="7" cx="2" cy="7" r="2" fill="black" />
        <circle id="8" cx="7" cy="7" r="2" fill="black" />
        <circle id="9" cx="12" cy="7" r="2" fill="black" />
        <circle id="10" cx="17" cy="7" r="2" fill="black" />
        <circle id="11" cx="22" cy="7" r="2" fill="black" />
        <circle id="12" cx="27" cy="7" r="2" fill="black" />
        <circle id="13" cx="2" cy="12" r="2" fill="black" />
        <circle id="14" cx="7" cy="12" r="2" fill="black" />
        <circle id="19" cx="2" cy="17" r="2" fill="black" />
        <circle id="20" cx="7" cy="17" r="2" fill="black" />
        <circle id="21" cx="12" cy="17" r="2" fill="black" />
        <circle id="22" cx="17" cy="17" r="2" fill="black" />
        <circle id="23" cx="22" cy="17" r="2" fill="black" />
        <circle id="24" cx="27" cy="17" r="2" fill="black" />
        <circle id="25" cx="2" cy="22" r="2" fill="black" />
        <circle id="26" cx="7" cy="22" r="2" fill="black" />
        <circle id="27" cx="12" cy="22" r="2" fill="black" />
        <circle id="28" cx="17" cy="22" r="2" fill="black" />
        <circle id="29" cx="22" cy="22" r="2" fill="black" />
        <circle id="30" cx="27" cy="22" r="2" fill="black" />
        <circle id="31" cx="2" cy="27" r="2" fill="black" />
        <circle id="32" cx="7" cy="27" r="2" fill="black" />
        <circle id="37" cx="2" cy="32" r="2" fill="black" />
        <circle id="38" cx="7" cy="32" r="2" fill="black" />
        <circle id="39" cx="12" cy="32" r="2" fill="black" />
        <circle id="40" cx="17" cy="32" r="2" fill="black" />
        <circle id="41" cx="22" cy="32" r="2" fill="black" />
        <circle id="42" cx="27" cy="32" r="2" fill="black" />
        <circle id="43" cx="2" cy="37" r="2" fill="black" />
        <circle id="44" cx="7" cy="37" r="2" fill="black" />
        <circle id="45" cx="12" cy="37" r="2" fill="black" />
        <circle id="46" cx="17" cy="37" r="2" fill="black" />
        <circle id="47" cx="22" cy="37" r="2" fill="black" />
        <circle id="48" cx="27" cy="37" r="2" fill="black" />
      </g>
      <g id="E_2">
        <circle id="1_2" cx="154" cy="2" r="2" fill="black" />
        <circle id="2_2" cx="159" cy="2" r="2" fill="black" />
        <circle id="3_2" cx="164" cy="2" r="2" fill="black" />
        <circle id="4_2" cx="169" cy="2" r="2" fill="black" />
        <circle id="5_2" cx="174" cy="2" r="2" fill="black" />
        <circle id="6_2" cx="179" cy="2" r="2" fill="black" />
        <circle id="7_2" cx="154" cy="7" r="2" fill="black" />
        <circle id="8_2" cx="159" cy="7" r="2" fill="black" />
        <circle id="9_2" cx="164" cy="7" r="2" fill="black" />
        <circle id="10_2" cx="169" cy="7" r="2" fill="black" />
        <circle id="11_2" cx="174" cy="7" r="2" fill="black" />
        <circle id="12_2" cx="179" cy="7" r="2" fill="black" />
        <circle id="13_2" cx="154" cy="12" r="2" fill="black" />
        <circle id="14_2" cx="159" cy="12" r="2" fill="black" />
        <circle id="19_2" cx="154" cy="17" r="2" fill="black" />
        <circle id="20_2" cx="159" cy="17" r="2" fill="black" />
        <circle id="21_2" cx="164" cy="17" r="2" fill="black" />
        <circle id="22_2" cx="169" cy="17" r="2" fill="black" />
        <circle id="23_2" cx="174" cy="17" r="2" fill="black" />
        <circle id="24_2" cx="179" cy="17" r="2" fill="black" />
        <circle id="25_2" cx="154" cy="22" r="2" fill="black" />
        <circle id="26_2" cx="159" cy="22" r="2" fill="black" />
        <circle id="27_2" cx="164" cy="22" r="2" fill="black" />
        <circle id="28_2" cx="169" cy="22" r="2" fill="black" />
        <circle id="29_2" cx="174" cy="22" r="2" fill="black" />
        <circle id="30_2" cx="179" cy="22" r="2" fill="black" />
        <circle id="31_2" cx="154" cy="27" r="2" fill="black" />
        <circle id="32_2" cx="159" cy="27" r="2" fill="black" />
        <circle id="37_2" cx="154" cy="32" r="2" fill="black" />
        <circle id="38_2" cx="159" cy="32" r="2" fill="black" />
        <circle id="39_2" cx="164" cy="32" r="2" fill="black" />
        <circle id="40_2" cx="169" cy="32" r="2" fill="black" />
        <circle id="41_2" cx="174" cy="32" r="2" fill="black" />
        <circle id="42_2" cx="179" cy="32" r="2" fill="black" />
        <circle id="43_2" cx="154" cy="37" r="2" fill="black" />
        <circle id="44_2" cx="159" cy="37" r="2" fill="black" />
        <circle id="45_2" cx="164" cy="37" r="2" fill="black" />
        <circle id="46_2" cx="169" cy="37" r="2" fill="black" />
        <circle id="47_2" cx="174" cy="37" r="2" fill="black" />
        <circle id="48_2" cx="179" cy="37" r="2" fill="black" />
      </g>
      <g id="R">
        <circle id="1_3" cx="39" cy="2" r="2" fill="black" />
        <circle id="2_3" cx="44" cy="2" r="2" fill="black" />
        <circle id="3_3" cx="49" cy="2" r="2" fill="black" />
        <circle id="4_3" cx="54" cy="2" r="2" fill="black" />
        <circle id="5_3" cx="59" cy="2" r="2" fill="black" />
        <circle id="7_3" cx="39" cy="7" r="2" fill="black" />
        <circle id="8_3" cx="44" cy="7" r="2" fill="black" />
        <circle id="9_3" cx="49" cy="7" r="2" fill="black" />
        <circle id="10_3" cx="54" cy="7" r="2" fill="black" />
        <circle id="11_3" cx="59" cy="7" r="2" fill="black" />
        <circle id="12_3" cx="64" cy="7" r="2" fill="black" />
        <circle id="13_3" cx="39" cy="12" r="2" fill="black" />
        <circle id="14_3" cx="44" cy="12" r="2" fill="black" />
        <circle id="17_3" cx="59" cy="12" r="2" fill="black" />
        <circle id="18_3" cx="64" cy="12" r="2" fill="black" />
        <circle id="19_3" cx="39" cy="17" r="2" fill="black" />
        <circle id="20_3" cx="44" cy="17" r="2" fill="black" />
        <circle id="21_3" cx="49" cy="17" r="2" fill="black" />
        <circle id="22_3" cx="54" cy="17" r="2" fill="black" />
        <circle id="23_3" cx="59" cy="17" r="2" fill="black" />
        <circle id="24_3" cx="64" cy="17" r="2" fill="black" />
        <circle id="25_3" cx="39" cy="22" r="2" fill="black" />
        <circle id="26_3" cx="44" cy="22" r="2" fill="black" />
        <circle id="27_3" cx="49" cy="22" r="2" fill="black" />
        <circle id="28_3" cx="54" cy="22" r="2" fill="black" />
        <circle id="29_3" cx="59" cy="22" r="2" fill="black" />
        <circle id="30_3" cx="64" cy="22" r="2" fill="black" />
        <circle id="31_3" cx="39" cy="27" r="2" fill="black" />
        <circle id="32_3" cx="44" cy="27" r="2" fill="black" />
        <circle id="34_3" cx="54" cy="27" r="2" fill="black" />
        <circle id="35_3" cx="59" cy="27" r="2" fill="black" />
        <circle id="37_3" cx="39" cy="32" r="2" fill="black" />
        <circle id="38_3" cx="44" cy="32" r="2" fill="black" />
        <circle id="40_3" cx="54" cy="32" r="2" fill="black" />
        <circle id="41_3" cx="59" cy="32" r="2" fill="black" />
        <circle id="42_3" cx="64" cy="32" r="2" fill="black" />
        <circle id="43_3" cx="39" cy="37" r="2" fill="black" />
        <circle id="44_3" cx="44" cy="37" r="2" fill="black" />
        <circle id="47_3" cx="59" cy="37" r="2" fill="black" />
        <circle id="48_3" cx="64" cy="37" r="2" fill="black" />
      </g>
      <g id="R_2">
        <circle id="1_4" cx="191" cy="2" r="2" fill="black" />
        <circle id="2_4" cx="196" cy="2" r="2" fill="black" />
        <circle id="3_4" cx="201" cy="2" r="2" fill="black" />
        <circle id="4_4" cx="206" cy="2" r="2" fill="black" />
        <circle id="5_4" cx="211" cy="2" r="2" fill="black" />
        <circle id="7_4" cx="191" cy="7" r="2" fill="black" />
        <circle id="8_4" cx="196" cy="7" r="2" fill="black" />
        <circle id="9_4" cx="201" cy="7" r="2" fill="black" />
        <circle id="10_4" cx="206" cy="7" r="2" fill="black" />
        <circle id="11_4" cx="211" cy="7" r="2" fill="black" />
        <circle id="12_4" cx="216" cy="7" r="2" fill="black" />
        <circle id="13_4" cx="191" cy="12" r="2" fill="black" />
        <circle id="14_4" cx="196" cy="12" r="2" fill="black" />
        <circle id="17_4" cx="211" cy="12" r="2" fill="black" />
        <circle id="18_4" cx="216" cy="12" r="2" fill="black" />
        <circle id="19_4" cx="191" cy="17" r="2" fill="black" />
        <circle id="20_4" cx="196" cy="17" r="2" fill="black" />
        <circle id="21_4" cx="201" cy="17" r="2" fill="black" />
        <circle id="22_4" cx="206" cy="17" r="2" fill="black" />
        <circle id="23_4" cx="211" cy="17" r="2" fill="black" />
        <circle id="24_4" cx="216" cy="17" r="2" fill="black" />
        <circle id="25_4" cx="191" cy="22" r="2" fill="black" />
        <circle id="26_4" cx="196" cy="22" r="2" fill="black" />
        <circle id="27_4" cx="201" cy="22" r="2" fill="black" />
        <circle id="28_4" cx="206" cy="22" r="2" fill="black" />
        <circle id="29_4" cx="211" cy="22" r="2" fill="black" />
        <circle id="30_4" cx="216" cy="22" r="2" fill="black" />
        <circle id="31_4" cx="191" cy="27" r="2" fill="black" />
        <circle id="32_4" cx="196" cy="27" r="2" fill="black" />
        <circle id="34_4" cx="206" cy="27" r="2" fill="black" />
        <circle id="35_4" cx="211" cy="27" r="2" fill="black" />
        <circle id="37_4" cx="191" cy="32" r="2" fill="black" />
        <circle id="38_4" cx="196" cy="32" r="2" fill="black" />
        <circle id="40_4" cx="206" cy="32" r="2" fill="black" />
        <circle id="41_4" cx="211" cy="32" r="2" fill="black" />
        <circle id="42_4" cx="216" cy="32" r="2" fill="black" />
        <circle id="43_4" cx="191" cy="37" r="2" fill="black" />
        <circle id="44_4" cx="196" cy="37" r="2" fill="black" />
        <circle id="47_4" cx="211" cy="37" r="2" fill="black" />
        <circle id="48_4" cx="216" cy="37" r="2" fill="black" />
      </g>
      <g id="I">
        <circle id="2_5" cx="79" cy="2" r="2" fill="black" />
        <circle id="3_5" cx="84" cy="2" r="2" fill="black" />
        <circle id="4_5" cx="89" cy="2" r="2" fill="black" />
        <circle id="5_5" cx="94" cy="2" r="2" fill="black" />
        <circle id="8_5" cx="79" cy="7" r="2" fill="black" />
        <circle id="9_5" cx="84" cy="7" r="2" fill="black" />
        <circle id="10_5" cx="89" cy="7" r="2" fill="black" />
        <circle id="11_5" cx="94" cy="7" r="2" fill="black" />
        <circle id="15_5" cx="84" cy="12" r="2" fill="black" />
        <circle id="16_5" cx="89" cy="12" r="2" fill="black" />
        <circle id="21_5" cx="84" cy="17" r="2" fill="black" />
        <circle id="22_5" cx="89" cy="17" r="2" fill="black" />
        <circle id="27_5" cx="84" cy="22" r="2" fill="black" />
        <circle id="28_5" cx="89" cy="22" r="2" fill="black" />
        <circle id="33_5" cx="84" cy="27" r="2" fill="black" />
        <circle id="34_5" cx="89" cy="27" r="2" fill="black" />
        <circle id="38_5" cx="79" cy="32" r="2" fill="black" />
        <circle id="39_5" cx="84" cy="32" r="2" fill="black" />
        <circle id="40_5" cx="89" cy="32" r="2" fill="black" />
        <circle id="41_5" cx="94" cy="32" r="2" fill="black" />
        <circle id="44_5" cx="79" cy="37" r="2" fill="black" />
        <circle id="45_5" cx="84" cy="37" r="2" fill="black" />
        <circle id="46_5" cx="89" cy="37" r="2" fill="black" />
        <circle id="47_5" cx="94" cy="37" r="2" fill="black" />
      </g>
      <g id="I_2">
        <circle id="2_6" cx="231" cy="2" r="2" fill="black" />
        <circle id="3_6" cx="236" cy="2" r="2" fill="black" />
        <circle id="4_6" cx="241" cy="2" r="2" fill="black" />
        <circle id="5_6" cx="246" cy="2" r="2" fill="black" />
        <circle id="8_6" cx="231" cy="7" r="2" fill="black" />
        <circle id="9_6" cx="236" cy="7" r="2" fill="black" />
        <circle id="10_6" cx="241" cy="7" r="2" fill="black" />
        <circle id="11_6" cx="246" cy="7" r="2" fill="black" />
        <circle id="15_6" cx="236" cy="12" r="2" fill="black" />
        <circle id="16_6" cx="241" cy="12" r="2" fill="black" />
        <circle id="21_6" cx="236" cy="17" r="2" fill="black" />
        <circle id="22_6" cx="241" cy="17" r="2" fill="black" />
        <circle id="27_6" cx="236" cy="22" r="2" fill="black" />
        <circle id="28_6" cx="241" cy="22" r="2" fill="black" />
        <circle id="33_6" cx="236" cy="27" r="2" fill="black" />
        <circle id="34_6" cx="241" cy="27" r="2" fill="black" />
        <circle id="38_6" cx="231" cy="32" r="2" fill="black" />
        <circle id="39_6" cx="236" cy="32" r="2" fill="black" />
        <circle id="40_6" cx="241" cy="32" r="2" fill="black" />
        <circle id="41_6" cx="246" cy="32" r="2" fill="black" />
        <circle id="44_6" cx="231" cy="37" r="2" fill="black" />
        <circle id="45_6" cx="236" cy="37" r="2" fill="black" />
        <circle id="46_6" cx="241" cy="37" r="2" fill="black" />
        <circle id="47_6" cx="246" cy="37" r="2" fill="black" />
      </g>
      <g id="C">
        <circle id="2_7" cx="114" cy="2" r="2" fill="black" />
        <circle id="3_7" cx="119" cy="2" r="2" fill="black" />
        <circle id="4_7" cx="124" cy="2" r="2" fill="black" />
        <circle id="5_7" cx="129" cy="2" r="2" fill="black" />
        <circle id="7_7" cx="109" cy="7" r="2" fill="black" />
        <circle id="8_7" cx="114" cy="7" r="2" fill="black" />
        <circle id="9_7" cx="119" cy="7" r="2" fill="black" />
        <circle id="10_7" cx="124" cy="7" r="2" fill="black" />
        <circle id="11_7" cx="129" cy="7" r="2" fill="black" />
        <circle id="12_7" cx="134" cy="7" r="2" fill="black" />
        <circle id="13_7" cx="109" cy="12" r="2" fill="black" />
        <circle id="14_7" cx="114" cy="12" r="2" fill="black" />
        <circle id="17_7" cx="129" cy="12" r="2" fill="black" />
        <circle id="18_7" cx="134" cy="12" r="2" fill="black" />
        <circle id="19_7" cx="109" cy="17" r="2" fill="black" />
        <circle id="20_7" cx="114" cy="17" r="2" fill="black" />
        <circle id="25_7" cx="109" cy="22" r="2" fill="black" />
        <circle id="26_7" cx="114" cy="22" r="2" fill="black" />
        <circle id="31_7" cx="109" cy="27" r="2" fill="black" />
        <circle id="32_7" cx="114" cy="27" r="2" fill="black" />
        <circle id="35_7" cx="129" cy="27" r="2" fill="black" />
        <circle id="36_7" cx="134" cy="27" r="2" fill="black" />
        <circle id="37_7" cx="109" cy="32" r="2" fill="black" />
        <circle id="38_7" cx="114" cy="32" r="2" fill="black" />
        <circle id="39_7" cx="119" cy="32" r="2" fill="black" />
        <circle id="40_7" cx="124" cy="32" r="2" fill="black" />
        <circle id="41_7" cx="129" cy="32" r="2" fill="black" />
        <circle id="42_7" cx="134" cy="32" r="2" fill="black" />
        <circle id="44_7" cx="114" cy="37" r="2" fill="black" />
        <circle id="45_7" cx="119" cy="37" r="2" fill="black" />
        <circle id="46_7" cx="124" cy="37" r="2" fill="black" />
        <circle id="47_7" cx="129" cy="37" r="2" fill="black" />
      </g>
      <g id="K">
        <circle id="1_8" cx="261" cy="2" r="2" fill="black" />
        <circle id="2_8" cx="266" cy="2" r="2" fill="black" />
        <circle id="5_8" cx="281" cy="2" r="2" fill="black" />
        <circle id="6_8" cx="286" cy="2" r="2" fill="black" />
        <circle id="7_8" cx="261" cy="7" r="2" fill="black" />
        <circle id="8_8" cx="266" cy="7" r="2" fill="black" />
        <circle id="11_8" cx="281" cy="7" r="2" fill="black" />
        <circle id="12_8" cx="286" cy="7" r="2" fill="black" />
        <circle id="13_8" cx="261" cy="12" r="2" fill="black" />
        <circle id="14_8" cx="266" cy="12" r="2" fill="black" />
        <circle id="16_8" cx="276" cy="12" r="2" fill="black" />
        <circle id="17_8" cx="281" cy="12" r="2" fill="black" />
        <circle id="18_8" cx="286" cy="12" r="2" fill="black" />
        <circle id="19_8" cx="261" cy="17" r="2" fill="black" />
        <circle id="20_8" cx="266" cy="17" r="2" fill="black" />
        <circle id="21_8" cx="271" cy="17" r="2" fill="black" />
        <circle id="22_8" cx="276" cy="17" r="2" fill="black" />
        <circle id="23_8" cx="281" cy="17" r="2" fill="black" />
        <circle id="25_8" cx="261" cy="22" r="2" fill="black" />
        <circle id="26_8" cx="266" cy="22" r="2" fill="black" />
        <circle id="27_8" cx="271" cy="22" r="2" fill="black" />
        <circle id="28_8" cx="276" cy="22" r="2" fill="black" />
        <circle id="31_8" cx="261" cy="27" r="2" fill="black" />
        <circle id="32_8" cx="266" cy="27" r="2" fill="black" />
        <circle id="33_8" cx="271" cy="27" r="2" fill="black" />
        <circle id="34_8" cx="276" cy="27" r="2" fill="black" />
        <circle id="35_8" cx="281" cy="27" r="2" fill="black" />
        <circle id="37_8" cx="261" cy="32" r="2" fill="black" />
        <circle id="38_8" cx="266" cy="32" r="2" fill="black" />
        <circle id="40_8" cx="276" cy="32" r="2" fill="black" />
        <circle id="41_8" cx="281" cy="32" r="2" fill="black" />
        <circle id="42_8" cx="286" cy="32" r="2" fill="black" />
        <circle id="43_8" cx="261" cy="37" r="2" fill="black" />
        <circle id="44_8" cx="266" cy="37" r="2" fill="black" />
        <circle id="47_8" cx="281" cy="37" r="2" fill="black" />
        <circle id="48_8" cx="286" cy="37" r="2" fill="black" />
      </g>
      <g id="S">
        <circle id="2_9" cx="303" cy="2" r="2" fill="black" />
        <circle id="3_9" cx="308" cy="2" r="2" fill="black" />
        <circle id="4_9" cx="313" cy="2" r="2" fill="black" />
        <circle id="5_9" cx="318" cy="2" r="2" fill="black" />
        <circle id="7_9" cx="298" cy="7" r="2" fill="black" />
        <circle id="8_9" cx="303" cy="7" r="2" fill="black" />
        <circle id="9_9" cx="308" cy="7" r="2" fill="black" />
        <circle id="10_9" cx="313" cy="7" r="2" fill="black" />
        <circle id="11_9" cx="318" cy="7" r="2" fill="black" />
        <circle id="12_9" cx="323" cy="7" r="2" fill="black" />
        <circle id="13_9" cx="298" cy="12" r="2" fill="black" />
        <circle id="14_9" cx="303" cy="12" r="2" fill="black" />
        <circle id="19_9" cx="298" cy="17" r="2" fill="black" />
        <circle id="20_9" cx="303" cy="17" r="2" fill="black" />
        <circle id="21_9" cx="308" cy="17" r="2" fill="black" />
        <circle id="22_9" cx="313" cy="17" r="2" fill="black" />
        <circle id="23_9" cx="318" cy="17" r="2" fill="black" />
        <circle id="24_9" cx="323" cy="17" r="2" fill="black" />
        <circle id="25_9" cx="298" cy="22" r="2" fill="black" />
        <circle id="26_9" cx="303" cy="22" r="2" fill="black" />
        <circle id="27_9" cx="308" cy="22" r="2" fill="black" />
        <circle id="28_9" cx="313" cy="22" r="2" fill="black" />
        <circle id="29_9" cx="318" cy="22" r="2" fill="black" />
        <circle id="30_9" cx="323" cy="22" r="2" fill="black" />
        <circle id="35_9" cx="318" cy="27" r="2" fill="black" />
        <circle id="36_9" cx="323" cy="27" r="2" fill="black" />
        <circle id="37_9" cx="298" cy="32" r="2" fill="black" />
        <circle id="38_9" cx="303" cy="32" r="2" fill="black" />
        <circle id="39_9" cx="308" cy="32" r="2" fill="black" />
        <circle id="40_9" cx="313" cy="32" r="2" fill="black" />
        <circle id="41_9" cx="318" cy="32" r="2" fill="black" />
        <circle id="42_9" cx="323" cy="32" r="2" fill="black" />
        <circle id="44_9" cx="303" cy="37" r="2" fill="black" />
        <circle id="45_9" cx="308" cy="37" r="2" fill="black" />
        <circle id="46_9" cx="313" cy="37" r="2" fill="black" />
        <circle id="47_9" cx="318" cy="37" r="2" fill="black" />
      </g>
      <g id="S_2">
        <circle id="2_10" cx="340" cy="2" r="2" fill="black" />
        <circle id="3_10" cx="345" cy="2" r="2" fill="black" />
        <circle id="4_10" cx="350" cy="2" r="2" fill="black" />
        <circle id="5_10" cx="355" cy="2" r="2" fill="black" />
        <circle id="7_10" cx="335" cy="7" r="2" fill="black" />
        <circle id="8_10" cx="340" cy="7" r="2" fill="black" />
        <circle id="9_10" cx="345" cy="7" r="2" fill="black" />
        <circle id="10_10" cx="350" cy="7" r="2" fill="black" />
        <circle id="11_10" cx="355" cy="7" r="2" fill="black" />
        <circle id="12_10" cx="360" cy="7" r="2" fill="black" />
        <circle id="13_10" cx="335" cy="12" r="2" fill="black" />
        <circle id="14_10" cx="340" cy="12" r="2" fill="black" />
        <circle id="19_10" cx="335" cy="17" r="2" fill="black" />
        <circle id="20_10" cx="340" cy="17" r="2" fill="black" />
        <circle id="21_10" cx="345" cy="17" r="2" fill="black" />
        <circle id="22_10" cx="350" cy="17" r="2" fill="black" />
        <circle id="23_10" cx="355" cy="17" r="2" fill="black" />
        <circle id="24_10" cx="360" cy="17" r="2" fill="black" />
        <circle id="25_10" cx="335" cy="22" r="2" fill="black" />
        <circle id="26_10" cx="340" cy="22" r="2" fill="black" />
        <circle id="27_10" cx="345" cy="22" r="2" fill="black" />
        <circle id="28_10" cx="350" cy="22" r="2" fill="black" />
        <circle id="29_10" cx="355" cy="22" r="2" fill="black" />
        <circle id="30_10" cx="360" cy="22" r="2" fill="black" />
        <circle id="35_10" cx="355" cy="27" r="2" fill="black" />
        <circle id="36_10" cx="360" cy="27" r="2" fill="black" />
        <circle id="37_10" cx="335" cy="32" r="2" fill="black" />
        <circle id="38_10" cx="340" cy="32" r="2" fill="black" />
        <circle id="39_10" cx="345" cy="32" r="2" fill="black" />
        <circle id="40_10" cx="350" cy="32" r="2" fill="black" />
        <circle id="41_10" cx="355" cy="32" r="2" fill="black" />
        <circle id="42_10" cx="360" cy="32" r="2" fill="black" />
        <circle id="44_10" cx="340" cy="37" r="2" fill="black" />
        <circle id="45_10" cx="345" cy="37" r="2" fill="black" />
        <circle id="46_10" cx="350" cy="37" r="2" fill="black" />
        <circle id="47_10" cx="355" cy="37" r="2" fill="black" />
      </g>
      <g id="O">
        <circle id="2_11" cx="377" cy="2" r="2" fill="black" />
        <circle id="3_11" cx="382" cy="2" r="2" fill="black" />
        <circle id="4_11" cx="387" cy="2" r="2" fill="black" />
        <circle id="5_11" cx="392" cy="2" r="2" fill="black" />
        <circle id="7_11" cx="372" cy="7" r="2" fill="black" />
        <circle id="8_11" cx="377" cy="7" r="2" fill="black" />
        <circle id="9_11" cx="382" cy="7" r="2" fill="black" />
        <circle id="10_11" cx="387" cy="7" r="2" fill="black" />
        <circle id="11_11" cx="392" cy="7" r="2" fill="black" />
        <circle id="12_11" cx="397" cy="7" r="2" fill="black" />
        <circle id="13_11" cx="372" cy="12" r="2" fill="black" />
        <circle id="14_11" cx="377" cy="12" r="2" fill="black" />
        <circle id="17_11" cx="392" cy="12" r="2" fill="black" />
        <circle id="18_11" cx="397" cy="12" r="2" fill="black" />
        <circle id="19_11" cx="372" cy="17" r="2" fill="black" />
        <circle id="20_11" cx="377" cy="17" r="2" fill="black" />
        <circle id="23_11" cx="392" cy="17" r="2" fill="black" />
        <circle id="24_11" cx="397" cy="17" r="2" fill="black" />
        <circle id="25_11" cx="372" cy="22" r="2" fill="black" />
        <circle id="26_11" cx="377" cy="22" r="2" fill="black" />
        <circle id="29_11" cx="392" cy="22" r="2" fill="black" />
        <circle id="30_11" cx="397" cy="22" r="2" fill="black" />
        <circle id="31_11" cx="372" cy="27" r="2" fill="black" />
        <circle id="32_11" cx="377" cy="27" r="2" fill="black" />
        <circle id="35_11" cx="392" cy="27" r="2" fill="black" />
        <circle id="36_11" cx="397" cy="27" r="2" fill="black" />
        <circle id="37_11" cx="372" cy="32" r="2" fill="black" />
        <circle id="38_11" cx="377" cy="32" r="2" fill="black" />
        <circle id="39_11" cx="382" cy="32" r="2" fill="black" />
        <circle id="40_11" cx="387" cy="32" r="2" fill="black" />
        <circle id="41_11" cx="392" cy="32" r="2" fill="black" />
        <circle id="42_11" cx="397" cy="32" r="2" fill="black" />
        <circle id="44_11" cx="377" cy="37" r="2" fill="black" />
        <circle id="45_11" cx="382" cy="37" r="2" fill="black" />
        <circle id="46_11" cx="387" cy="37" r="2" fill="black" />
        <circle id="47_11" cx="392" cy="37" r="2" fill="black" />
      </g>
      <g id="N">
        <circle id="1_12" cx="409" cy="2" r="2" fill="black" />
        <circle id="2_12" cx="414" cy="2" r="2" fill="black" />
        <circle id="5_12" cx="429" cy="2" r="2" fill="black" />
        <circle id="6_12" cx="434" cy="2" r="2" fill="black" />
        <circle id="7_12" cx="409" cy="7" r="2" fill="black" />
        <circle id="8_12" cx="414" cy="7" r="2" fill="black" />
        <circle id="11_12" cx="429" cy="7" r="2" fill="black" />
        <circle id="12_12" cx="434" cy="7" r="2" fill="black" />
        <circle id="13_12" cx="409" cy="12" r="2" fill="black" />
        <circle id="14_12" cx="414" cy="12" r="2" fill="black" />
        <circle id="15_12" cx="419" cy="12" r="2" fill="black" />
        <circle id="17_12" cx="429" cy="12" r="2" fill="black" />
        <circle id="18_12" cx="434" cy="12" r="2" fill="black" />
        <circle id="19_12" cx="409" cy="17" r="2" fill="black" />
        <circle id="20_12" cx="414" cy="17" r="2" fill="black" />
        <circle id="21_12" cx="419" cy="17" r="2" fill="black" />
        <circle id="22_12" cx="424" cy="17" r="2" fill="black" />
        <circle id="23_12" cx="429" cy="17" r="2" fill="black" />
        <circle id="24_12" cx="434" cy="17" r="2" fill="black" />
        <circle id="25_12" cx="409" cy="22" r="2" fill="black" />
        <circle id="26_12" cx="414" cy="22" r="2" fill="black" />
        <circle id="27_12" cx="419" cy="22" r="2" fill="black" />
        <circle id="28_12" cx="424" cy="22" r="2" fill="black" />
        <circle id="29_12" cx="429" cy="22" r="2" fill="black" />
        <circle id="30_12" cx="434" cy="22" r="2" fill="black" />
        <circle id="31_12" cx="409" cy="27" r="2" fill="black" />
        <circle id="32_12" cx="414" cy="27" r="2" fill="black" />
        <circle id="34_12" cx="424" cy="27" r="2" fill="black" />
        <circle id="35_12" cx="429" cy="27" r="2" fill="black" />
        <circle id="36_12" cx="434" cy="27" r="2" fill="black" />
        <circle id="37_12" cx="409" cy="32" r="2" fill="black" />
        <circle id="38_12" cx="414" cy="32" r="2" fill="black" />
        <circle id="41_12" cx="429" cy="32" r="2" fill="black" />
        <circle id="42_12" cx="434" cy="32" r="2" fill="black" />
        <circle id="43_12" cx="409" cy="37" r="2" fill="black" />
        <circle id="44_12" cx="414" cy="37" r="2" fill="black" />
        <circle id="47_12" cx="429" cy="37" r="2" fill="black" />
        <circle id="48_12" cx="434" cy="37" r="2" fill="black" />
      </g>
    </g>
  </svg>
);

export default SvgName;
