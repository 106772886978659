import React, { useEffect, useState } from "react";
import "./App.css";
import Projects from "./components/Projects";
import DiagonalLines from "./components/DiagonalLines";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Experience from "./components/Experience";
import Marquee from "./components/Marquee";
import { Analytics } from "@vercel/analytics/react";

function App() {
  // Initialize theme based on user's system preference
  const [theme, setTheme] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  );

  useEffect(() => {
    // Apply the initial theme to the body class
    document.body.classList.toggle("dark-theme", theme === "dark");

    // Optional: Listen for changes in the system's theme and update accordingly
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      const newTheme = e.matches ? "dark" : "light";
      setTheme(newTheme);
      document.body.classList.toggle("dark-theme", newTheme === "dark");
    };
    mediaQuery.addListener(handleChange);

    // Cleanup listener on component unmount
    return () => mediaQuery.removeListener(handleChange);
  }, []); // Empty dependency array means this effect runs once on mount

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.body.classList.toggle("dark-theme", newTheme === "dark");
  };

  return (
    <div>
      <Header />
      <div className="App">
        <button className="theme-toggle-button" onClick={toggleTheme}>
          {theme === "dark" ? "😴" : "🤩"}
        </button>
        <DiagonalLines />
        <Marquee message="ATTENTION :: I AM NO LONGER AVAILABLE FOR WORK // KINDLY DO NOT @ ME" />
        <Experience />
        <div className="selected-work">
          <Projects />
        </div>
      </div>
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;
