import Project from "../components/Project";
import ramp1 from "../assets/ramp1.png";
import ramp2 from "../assets/ramp2.png";
import ramp3 from "../assets/ramp3.png";
import ramp4 from "../assets/ramp4.png";
import ramp5 from "../assets/ramp5.png";
import ramp6 from "../assets/ramp6.png";
import ramp7 from "../assets/ramp7.png";
import sqsp1 from "../assets/sqsp1.png";
import sqsp2 from "../assets/sqsp2.png";
import sqsp3 from "../assets/sqsp3.png";
import sqsp4 from "../assets/sqsp4.png";
import sqsp5 from "../assets/sqsp5.png";
import insta1 from "../assets/insta1.png";
import insta2 from "../assets/insta2.png";
import insta3 from "../assets/insta3.png";
import insta4 from "../assets/insta4.png";
import insta5 from "../assets/insta5.png";
import insta6 from "../assets/insta6.png";
import insta7 from "../assets/insta7.png";
import fb1 from "../assets/fb1.png";
import fb2 from "../assets/fb2.png";
import fb3 from "../assets/fb3.png";
import fb4 from "../assets/fb4.png";
import fb5 from "../assets/fb5.png";
import aether1 from "../assets/aether1.png";
import aether2 from "../assets/aether2.png";
import aether3 from "../assets/aether3.png";
import links from "../assets/Links.js";

const Projects = () => {
  const projects = [
    {
      name: "ramp mobile apps",
      images: [ramp1, ramp7, ramp2, ramp3, ramp5, ramp4, ramp6],
      showCircle: true,
      circleText: "• 4.9 stars on the app store! • • •",
      desc: "In 2021, without a mobile team or app, I and three engineers built Ramp's initial mobile offering in three months. I led the product and design strategy, designed the app, prototyped key interactions, conducted user interviews, and co-wrote production code, resulting in a 4.9-star app. This project was a highlight of my time at Ramp.",
      links: [
        {
          name: "Ramp.com",
          url: "https://ramp.com/mobile-app",
        },
        {
          name: "App store",
          url: "https://apps.apple.com/us/app/ramp/id1628197245",
        },
        {
          name: "Play store",
          url: "https://play.google.com/store/apps/details?id=com.ramp.android.app&hl=en_US&gl=US",
        },
      ],
    },
    {
      name: "sqsp mobile apps",
      images: [sqsp1, sqsp2, sqsp3, sqsp4, sqsp5],
      showCircle: false,
      desc: "At Squarespace, I focused on social selling, designing an app that, though unreleased, informed what eventually became `bio.sites.` I also unified Squarespace's core apps — sqsp, analytics, and commerce — into one, merging three design systems and streamlining user experience into a single companion app.",
      links: [
        {
          name: "App store",
          url: "https://apps.apple.com/us/app/squarespace-run-your-business/id1361797894?platform=iphone",
        },
        {
          name: "Play store",
          url: "https://play.google.com/store/apps/details?id=com.squarespace.android.squarespaceapp&hl=en_US&gl=US",
        },
      ],
    },
    {
      name: "the aethermind",
      images: [aether1, aether2, aether3],
      showCircle: false,
      desc: "an AI deck builder for Magic the Gathering's Commander/edh format. Designed and built entirely by me. just a little side project that I'm proud of. written in react and uses firebase, gpt-4, and scryfall apis.",
      links: [
        {
          name: "The Aethermind",
          url: "https://aethermind.vercel.app",
        },
      ],
    },
    {
      name: "fb donate",
      images: [fb1, fb2, fb3, fb4, fb5],
      showCircle: true,
      circleText: "• • ~ $6b raised for charity! • • •",
      desc: "As the founding designer of the Social Good Team at Meta, I explored various initiatives to leverage Facebook for good, ultimately focusing on charitable giving. This effort was pivotal during crises like the Ebola outbreak and Nepal earthquake, raising $20 million through donation flows and safety check features. The platform has since enabled over $7 billion in charity donations, significantly amplifying the impact of social fundraising.",
      links: [
        {
          name: "Fast Company, Innovation by Design Award nominee",
          url: "www.fastcodesign.com/3049911/the-2015-innovation-by-design-awards-winners-social-good",
        },
        {
          name: "Meta Newsroom Post",
          url: "https://about.fb.com/news/2022/11/fundraise-for-nonprofits-giving-season/",
        },
        {
          name: "Post by Mark Zuckerberg",
          url: "https://www.facebook.com/zuck/videos/vb.4/10102073772475131/",
        },
        {
          name: "Meta Newsroom video",
          url: "https://www.facebook.com/watch/?ref=embed_video&v=1481472255598048",
        },
        {
          name: "Fundraising Tools",
          url: "https://www.facebook.com/government-nonprofits/best-practices/nonprofits",
        },
      ],
    },
    {
      name: "ig comments",
      images: [insta1, insta3, insta2, insta4, insta5, insta6, insta7],
      showCircle: false,
      desc: "I led the design of the commenting functionality — including threaded conversations and the in-feed composer. I also designed several powerful anti-bullying and safety features, such as automatically hiding toxic comments, and custom banned words. ",
      links: [
        {
          name: "Instagram Blog Post #1",
          url: "https://instagram-press.com/blog/2017/08/15/keep-the-conversation-going-with-comment-threads/",
        },
        {
          name: "Article on Time.com",
          url: "http://time.com/4960114/instagram-comment-control-feature/",
        },
      ],
    },
    // {
    //   name: "instagram prototypes",
    //   images: ["img5.jpg", "img6.jpg"],
    //   showCircle: false,
    // },
    // {
    //   name: "facebook events",
    //   images: ["img5.jpg", "img6.jpg"],
    //   showCircle: false,
    // },
  ];
  return (
    <div className="Projects">
      {projects.map((project, index) => (
        <Project
          key={index}
          eyebrow={`work sample —— 0${index + 1}`}
          {...project}
        />
      ))}
    </div>
  );
};

export default Projects;
