export const LogoCrown = () => (
  <svg
    width="141"
    height="141"
    viewBox="0 0 141 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="logo-crown">
      <path
        id="ring"
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.5 125.246C102.809 125.246 129 98.7655 129 66.1004C129 50.2595 122.84 35.8731 112.811 25.2559C124.035 36.266 131 51.6208 131 68.6066C131 102.102 103.913 129.256 70.5 129.256C37.0868 129.256 10 102.102 10 68.6066C10 51.6209 16.9655 36.266 28.1892 25.256C18.1595 35.8732 12 50.2595 12 66.1004C12 98.7655 38.1913 125.246 70.5 125.246Z"
        fill="black"
      />
      <g id="right-leaves">
        <path
          id="leaf"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M101.021 138.165C97.8631 139.599 92.9435 139.703 87.8347 138.156C82.896 136.66 78.9602 133.979 77.0483 131.142C80.0898 129.45 85.1153 129.196 90.3281 130.774C95.3825 132.305 99.3347 135.171 101.021 138.165Z"
          fill="url(#paint0_linear_400_3594)"
        />
        <path
          id="leaf_2"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M110.476 128.038C108.271 129.972 104.311 131.327 99.7912 131.447C95.4221 131.562 91.5737 130.495 89.3267 128.782C91.374 126.676 95.3829 125.176 99.9945 125.054C104.466 124.936 108.373 126.143 110.476 128.038Z"
          fill="url(#paint1_linear_400_3594)"
        />
        <path
          id="leaf_3"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M120.731 118.623C118.909 120.612 115.443 122.39 111.355 123.21C107.404 124.002 103.817 123.711 101.621 122.618C103.284 120.509 106.782 118.602 110.952 117.765C114.996 116.954 118.65 117.354 120.731 118.623Z"
          fill="url(#paint2_linear_400_3594)"
        />
        <path
          id="leaf_4"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M129.582 107.561C128.201 109.877 125.166 112.321 121.33 113.952C117.622 115.529 114.049 115.971 111.678 115.345C112.879 112.943 115.917 110.366 119.832 108.702C123.627 107.088 127.286 106.74 129.582 107.561Z"
          fill="url(#paint3_linear_400_3594)"
        />
        <path
          id="leaf_5"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M135.168 95.9593C134.464 98.4295 131.81 101.505 128.132 103.817C125.158 105.687 122.179 106.641 119.988 106.595C120.477 104.097 123.099 100.899 126.831 98.5525C129.906 96.619 133.001 95.7258 135.168 95.9593Z"
          fill="url(#paint4_linear_400_3594)"
        />
        <path
          id="leaf_6"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M138.784 83.566C138.529 85.8816 136.623 89.0342 133.685 91.6603C131.31 93.7833 128.791 95.0884 126.825 95.38C126.884 93.0722 128.743 89.8145 131.723 87.1502C134.179 84.9545 136.81 83.6861 138.784 83.566Z"
          fill="url(#paint5_linear_400_3594)"
        />
        <path
          id="leaf_7"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M139.456 70.9637C139.799 73.2087 138.762 76.6247 136.597 79.781C134.846 82.3329 132.749 84.1455 130.928 84.8736C130.397 82.6812 131.361 79.1769 133.558 75.9747C135.368 73.3354 137.583 71.5315 139.456 70.9637Z"
          fill="url(#paint6_linear_400_3594)"
        />
        <path
          id="leaf_8"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M139.075 58.8297C139.433 60.9316 138.73 64.3275 137.111 67.619C135.803 70.2795 134.189 72.2781 132.756 73.206C132.248 71.1783 132.891 67.7072 134.533 64.3679C135.886 61.6163 137.594 59.611 139.075 58.8297Z"
          fill="url(#paint7_linear_400_3594)"
        />
        <path
          id="leaf_9"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M135.08 47.9999C135.937 49.871 135.987 53.1576 135.063 56.535C134.316 59.2654 133.099 61.4457 131.823 62.5978C130.829 60.8257 130.698 57.482 131.635 54.0555C132.408 51.2316 133.722 49.0258 135.08 47.9999Z"
          fill="url(#paint8_linear_400_3594)"
        />
        <path
          id="leaf_10"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M130.759 38.0904C131.806 39.6962 132.305 42.7172 131.919 45.956C131.607 48.5743 130.786 50.7506 129.769 51.9877C128.609 50.492 128.028 47.4296 128.42 44.1437C128.743 41.4357 129.649 39.2225 130.759 38.0904Z"
          fill="url(#paint9_linear_400_3594)"
        />
        <path
          id="leaf_11"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M126.161 31.1656C127.306 32.4098 128.14 34.9595 128.207 37.8243C128.261 40.1401 127.801 42.1451 127.046 43.3634C125.814 42.2308 124.9 39.6569 124.833 36.7505C124.777 34.3554 125.308 32.3062 126.161 31.1656Z"
          fill="url(#paint10_linear_400_3594)"
        />
        <path
          id="leaf_12"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M122.285 35.836C120.965 35.0761 119.798 32.9828 119.415 30.4324C119.1 28.3306 119.393 26.4202 120.096 25.2491C121.344 26.1244 122.431 28.2117 122.808 30.7256C123.113 32.7577 122.885 34.6152 122.285 35.836Z"
          fill="url(#paint11_linear_400_3594)"
        />
        <path
          id="leaf_13"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M117.203 28.8082C115.834 28.3423 114.452 26.6031 113.776 24.292C113.219 22.3874 113.273 20.549 113.813 19.3253C115.126 19.9123 116.43 21.6607 117.096 23.9387C117.635 25.7802 117.638 27.557 117.203 28.8082Z"
          fill="url(#paint12_linear_400_3594)"
        />
        <path
          id="leaf_14"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M112.261 22.9406C111.032 22.8326 109.628 21.6528 108.752 19.8439C108.03 18.3532 107.84 16.7912 108.144 15.6465C109.341 15.8685 110.679 17.0721 111.543 18.8551C112.24 20.2964 112.472 21.7947 112.261 22.9406Z"
          fill="url(#paint13_linear_400_3594)"
        />
        <path
          id="leaf_15"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M107.313 18.9478C105.995 18.9187 104.309 17.7896 103.072 15.9725C102.052 14.475 101.581 12.8677 101.704 11.6599C103.007 11.8095 104.628 12.9677 105.848 14.7589C106.834 16.2068 107.337 17.7452 107.313 18.9478Z"
          fill="url(#paint14_linear_400_3594)"
        />
        <path
          id="leaf_16"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M101.637 15.0408C100.43 15.3371 98.699 14.674 97.2602 13.2434C96.0743 12.0643 95.3704 10.6454 95.2768 9.46182C96.4909 9.28411 98.1682 9.991 99.5864 11.4011C100.733 12.5411 101.454 13.8864 101.637 15.0408Z"
          fill="url(#paint15_linear_400_3594)"
        />
        <path
          id="leaf_17"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M94.9476 11.681C94.0838 12.1559 92.7408 11.91 91.5409 10.9561C90.5519 10.1698 89.8961 9.09033 89.714 8.09891C90.5944 7.7237 91.9015 8.01694 93.0843 8.95722C94.0405 9.71741 94.7021 10.7309 94.9476 11.681Z"
          fill="url(#paint16_linear_400_3594)"
        />
        <path
          id="leaf_18"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M86.3883 100.502C84.239 102.767 82.3572 106.973 81.5592 111.822C80.7859 116.519 81.2176 120.681 82.5058 123.093C84.7945 121.01 86.8239 116.752 87.6392 111.799C88.4313 106.987 87.8735 102.755 86.3883 100.502Z"
          fill="url(#paint17_linear_400_3594)"
        />
        <path
          id="leaf_19"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M97.4144 99.3444C94.9873 101.177 92.5001 104.914 90.9459 109.44C89.4401 113.826 89.1753 117.888 90.0158 120.408C92.5465 118.773 95.1835 115.01 96.7712 110.386C98.3138 105.893 98.4692 101.743 97.4144 99.3444Z"
          fill="url(#paint18_linear_400_3594)"
        />
        <path
          id="leaf_20"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.743 94.2825C102.813 96.1329 101.096 99.6629 100.329 103.794C99.5857 107.796 99.9154 111.393 101.025 113.524C103.076 111.837 104.925 108.269 105.708 104.049C106.469 99.9485 106.029 96.2865 104.743 94.2825Z"
          fill="url(#paint19_linear_400_3594)"
        />
        <path
          id="leaf_21"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M112.566 87.1982C110.984 89.1593 109.756 92.6642 109.45 96.6158C109.154 100.445 109.797 103.764 111.011 105.621C112.718 103.798 114.063 100.242 114.374 96.2051C114.677 92.2827 113.928 88.9148 112.566 87.1982Z"
          fill="url(#paint20_linear_400_3594)"
        />
        <path
          id="leaf_22"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.148 79.0651C114.176 80.8808 113.88 83.8498 114.518 87.0112C115.137 90.0743 116.484 92.5751 118.006 93.8267C119.123 92.098 119.513 89.0686 118.86 85.8391C118.227 82.7012 116.772 80.181 115.148 79.0651Z"
          fill="url(#paint21_linear_400_3594)"
        />
        <path
          id="leaf_23"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M117.522 70.4522C116.953 72.4314 117.286 75.3967 118.574 78.3536C119.822 81.2185 121.664 83.3809 123.416 84.2848C124.145 82.36 123.889 79.3164 122.574 76.2958C121.295 73.3609 119.344 71.2022 117.522 70.4522Z"
          fill="url(#paint22_linear_400_3594)"
        />
        <path
          id="leaf_24"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M117.63 62.0204C117.599 63.9768 118.577 66.6675 120.377 69.157C122.121 71.5692 124.213 73.2075 125.935 73.6936C126.117 71.7554 125.188 68.976 123.349 66.4328C121.563 63.9617 119.377 62.3489 117.63 62.0204Z"
          fill="url(#paint23_linear_400_3594)"
        />
        <path
          id="leaf_25"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M116.384 53.2935C116.769 55.1613 118.262 57.5225 120.489 59.5248C122.648 61.4649 124.972 62.5976 126.702 62.7093C126.464 60.8282 124.998 58.3726 122.723 56.3271C120.512 54.3397 118.105 53.2503 116.384 53.2935Z"
          fill="url(#paint24_linear_400_3594)"
        />
        <path
          id="leaf_26"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.324 46.675C115.859 48.2503 117.444 50.0685 119.657 51.4447C121.801 52.7781 124.015 53.3734 125.589 53.1736C125.185 51.5612 123.615 49.6553 121.355 48.2494C119.158 46.8834 116.874 46.3406 115.324 46.675Z"
          fill="url(#paint25_linear_400_3594)"
        />
        <path
          id="leaf_27"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M113.39 40.7434C114.089 42.1476 115.784 43.684 117.998 44.759C120.142 45.8004 122.253 46.1562 123.672 45.8355C123.089 44.3859 121.396 42.7674 119.135 41.6693C116.938 40.6024 114.77 40.301 113.39 40.7434Z"
          fill="url(#paint26_linear_400_3594)"
        />
        <path
          id="leaf_28"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M110.185 35.6191C111.046 36.7754 112.813 37.9562 114.959 38.6892C117.037 39.3992 118.968 39.517 120.17 39.11C119.403 37.9037 117.625 36.6514 115.433 35.9026C113.303 35.1752 111.331 35.1099 110.185 35.6191Z"
          fill="url(#paint27_linear_400_3594)"
        />
        <path
          id="leaf_29"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M108.147 31.7328C108.998 32.684 110.662 33.5199 112.632 33.8788C114.541 34.2264 116.275 34.042 117.321 33.4776C116.55 32.4652 114.871 31.5641 112.858 31.1975C110.902 30.8414 109.134 31.0813 108.147 31.7328Z"
          fill="url(#paint28_linear_400_3594)"
        />
        <path
          id="leaf_30"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105.129 27.2304C105.811 28.0433 107.169 28.7825 108.796 29.1339C110.372 29.4745 111.818 29.3683 112.701 28.9276C112.088 28.066 110.719 27.2723 109.057 26.9133C107.442 26.5644 105.967 26.7158 105.129 27.2304Z"
          fill="url(#paint29_linear_400_3594)"
        />
        <path
          id="leaf_31"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.13 24.18C102.814 24.992 104.184 25.6571 105.828 25.876C107.421 26.088 108.886 25.8297 109.783 25.2652C109.169 24.3938 107.788 23.6708 106.108 23.4472C104.476 23.23 102.982 23.5401 102.13 24.18Z"
          fill="url(#paint30_linear_400_3594)"
        />
        <path
          id="leaf_32"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.3408 21.7544C99.9954 22.3071 101.175 22.6498 102.513 22.6004C103.808 22.5525 104.938 22.148 105.575 21.5771C104.968 20.9676 103.771 20.5804 102.405 20.6309C101.078 20.68 99.9314 21.1297 99.3408 21.7544Z"
          fill="url(#paint31_linear_400_3594)"
        />
        <path
          id="leaf_33"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M97.0508 18.8396C97.6273 19.2722 98.6412 19.5693 99.7732 19.5829C100.87 19.5961 101.812 19.3399 102.33 18.9424C101.791 18.4698 100.762 18.1391 99.6052 18.1252C98.4817 18.1117 97.5273 18.4007 97.0508 18.8396Z"
          fill="url(#paint32_linear_400_3594)"
        />
        <path
          id="leaf_34"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M95.077 16.4442C95.4507 16.9017 96.212 17.2728 97.1297 17.3881C98.017 17.4997 98.8347 17.345 99.3361 17.02C99.0025 16.5283 98.2353 16.1241 97.2976 16.0062C96.3882 15.8919 95.553 16.0763 95.077 16.4442Z"
          fill="url(#paint33_linear_400_3594)"
        />
        <path
          id="leaf_35"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.077 14.4442C92.4507 14.9017 93.212 15.2728 94.1297 15.3881C95.017 15.4997 95.8347 15.345 96.3361 15.02C96.0025 14.5283 95.2353 14.1241 94.2976 14.0062C93.3882 13.8919 92.553 14.0763 92.077 14.4442Z"
          fill="url(#paint34_linear_400_3594)"
        />
        <path
          id="leaf_36"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.3157 11.7657C85.0465 12.5468 86.462 13.2231 88.1253 13.4967C89.7337 13.7613 91.1824 13.5849 92.0406 13.101C91.3774 12.2678 89.9469 11.5369 88.2474 11.2574C86.5989 10.9862 85.1227 11.2096 84.3157 11.7657Z"
          fill="url(#paint35_linear_400_3594)"
        />
      </g>
      <g id="left-leaves">
        <path
          id="leaf_37"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.1753 138.165C42.3332 139.599 47.2528 139.703 52.3616 138.156C57.3003 136.66 61.2361 133.979 63.148 131.142C60.1065 129.45 55.081 129.196 49.8682 130.774C44.8138 132.305 40.8616 135.171 39.1753 138.165Z"
          fill="url(#paint36_linear_400_3594)"
        />
        <path
          id="leaf_38"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.7201 128.038C31.9255 129.972 35.8856 131.327 40.4051 131.447C44.7742 131.562 48.6225 130.495 50.8696 128.782C48.8222 126.676 44.8134 125.176 40.2018 125.054C35.7304 124.936 31.8232 126.143 29.7201 128.038Z"
          fill="url(#paint37_linear_400_3594)"
        />
        <path
          id="leaf_39"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.465 118.623C21.287 120.612 24.7534 122.39 28.8409 123.21C32.792 124.002 36.3796 123.711 38.5748 122.618C36.912 120.509 33.4146 118.602 29.2439 117.765C25.2003 116.954 21.5464 117.354 19.465 118.623Z"
          fill="url(#paint38_linear_400_3594)"
        />
        <path
          id="leaf_40"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6145 107.561C11.9956 109.877 15.0299 112.321 18.8664 113.952C22.5747 115.529 26.147 115.971 28.5181 115.345C27.3172 112.943 24.279 110.366 20.3644 108.702C16.5691 107.088 12.91 106.74 10.6145 107.561Z"
          fill="url(#paint39_linear_400_3594)"
        />
        <path
          id="leaf_41"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.02844 95.9594C5.73276 98.4295 8.38679 101.505 12.0648 103.817C15.0381 105.687 18.0171 106.641 20.208 106.595C19.7189 104.097 17.0968 100.899 13.3653 98.5526C10.2901 96.619 7.19572 95.7258 5.02844 95.9594Z"
          fill="url(#paint40_linear_400_3594)"
        />
        <path
          id="leaf_42"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.41264 83.566C1.66744 85.8816 3.5737 89.0342 6.5113 91.6603C8.88611 93.7833 11.405 95.0884 13.3709 95.3801C13.3127 93.0722 11.4537 89.8145 8.47341 87.1502C6.01724 84.9545 3.38596 83.6861 1.41264 83.566Z"
          fill="url(#paint41_linear_400_3594)"
        />
        <path
          id="leaf_43"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.740692 70.9638C0.397779 73.2088 1.43453 76.6247 3.59958 79.781C5.34999 82.3329 7.44726 84.1456 9.26875 84.8736C9.79937 82.6812 8.83488 79.1769 6.63833 75.9747C4.82795 73.3354 2.61296 71.5315 0.740692 70.9638Z"
          fill="url(#paint42_linear_400_3594)"
        />
        <path
          id="leaf_44"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.12165 58.8297C0.762841 60.9316 1.46665 64.3275 3.08502 67.619C4.39317 70.2795 6.00701 72.2781 7.44049 73.206C7.94779 71.1783 7.30523 67.7072 5.66335 64.3679C4.31039 61.6163 2.60186 59.6111 1.12165 58.8297Z"
          fill="url(#paint43_linear_400_3594)"
        />
        <path
          id="leaf_45"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.11599 47.9999C4.25945 49.871 4.20918 53.1577 5.13321 56.5351C5.88021 59.2654 7.09727 61.4458 8.37323 62.5978C9.36705 60.8257 9.49827 57.482 8.5608 54.0555C7.7882 51.2316 6.47424 49.0258 5.11599 47.9999Z"
          fill="url(#paint44_linear_400_3594)"
        />
        <path
          id="leaf_46"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.43699 38.0904C8.39015 39.6962 7.89118 42.7172 8.27734 45.956C8.58951 48.5743 9.41048 50.7506 10.4273 51.9877C11.587 50.4921 12.1684 47.4296 11.7767 44.1437C11.4538 41.4357 10.547 39.2226 9.43699 38.0904Z"
          fill="url(#paint45_linear_400_3594)"
        />
        <path
          id="leaf_47"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.0355 31.1656C12.8902 32.4098 12.0562 34.9595 11.9894 37.8243C11.9354 40.1401 12.3948 42.1451 13.1501 43.3634C14.3827 42.2308 15.2959 39.6569 15.3637 36.7505C15.4195 34.3554 14.8878 32.3062 14.0355 31.1656Z"
          fill="url(#paint46_linear_400_3594)"
        />
        <path
          id="leaf_48"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9114 35.836C19.2315 35.0761 20.3986 32.9829 20.7811 30.4325C21.0963 28.3307 20.8033 26.4203 20.1001 25.2491C18.8525 26.1244 17.765 28.2117 17.3881 30.7256C17.0833 32.7578 17.3109 34.6152 17.9114 35.836Z"
          fill="url(#paint47_linear_400_3594)"
        />
        <path
          id="leaf_49"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.9934 28.8082C24.3627 28.3424 25.744 26.6032 26.4203 24.2921C26.9777 22.3874 26.9232 20.5491 26.3828 19.3253C25.0698 19.9123 23.7665 21.6607 23.0998 23.9387C22.5609 25.7803 22.5584 27.557 22.9934 28.8082Z"
          fill="url(#paint48_linear_400_3594)"
        />
        <path
          id="leaf_50"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.9349 22.9406C29.1646 22.8326 30.5686 21.6529 31.4443 19.844C32.166 18.3533 32.3565 16.7912 32.0527 15.6466C30.8556 15.8685 29.5169 17.0722 28.6537 18.8551C27.956 20.2965 27.7247 21.7947 27.9349 22.9406Z"
          fill="url(#paint49_linear_400_3594)"
        />
        <path
          id="leaf_51"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.8838 18.9479C34.201 18.9187 35.8876 17.7897 37.1248 15.9725C38.1444 14.475 38.6154 12.8677 38.4922 11.66C37.1897 11.8095 35.568 12.9678 34.3485 14.7589C33.3627 16.2068 32.8597 17.7452 32.8838 18.9479Z"
          fill="url(#paint50_linear_400_3594)"
        />
        <path
          id="leaf_52"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.5589 15.0408C39.7659 15.3371 41.4973 14.6741 42.9361 13.2434C44.1219 12.0644 44.8259 10.6454 44.9195 9.46185C43.7053 9.28414 42.0281 9.99103 40.6099 11.4012C39.4633 12.5412 38.742 13.8864 38.5589 15.0408Z"
          fill="url(#paint51_linear_400_3594)"
        />
        <path
          id="leaf_53"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.2487 11.681C46.1125 12.1559 47.4554 11.91 48.6554 10.9561C49.6444 10.1699 50.3001 9.09036 50.4823 8.09894C49.6019 7.72373 48.2948 8.01697 47.112 8.95725C46.1558 9.71744 45.4942 10.7309 45.2487 11.681Z"
          fill="url(#paint52_linear_400_3594)"
        />
        <path
          id="leaf_54"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.807 100.502C55.9563 102.767 57.8381 106.973 58.6362 111.822C59.4094 116.519 58.9777 120.681 57.6895 123.093C55.4008 121.01 53.3714 116.752 52.5562 111.799C51.764 106.987 52.3218 102.756 53.807 100.502Z"
          fill="url(#paint53_linear_400_3594)"
        />
        <path
          id="leaf_55"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.7819 99.3444C45.209 101.177 47.6962 104.914 49.2504 109.44C50.7562 113.826 51.021 117.888 50.1805 120.408C47.6498 118.773 45.0128 115.01 43.4251 110.386C41.8825 105.893 41.7271 101.743 42.7819 99.3444Z"
          fill="url(#paint54_linear_400_3594)"
        />
        <path
          id="leaf_56"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.453 94.2826C37.383 96.1329 39.1006 99.6629 39.8675 103.794C40.6106 107.796 40.2808 111.393 39.1713 113.524C37.1203 111.837 35.2714 108.269 34.488 104.049C33.7268 99.9485 34.1673 96.2865 35.453 94.2826Z"
          fill="url(#paint55_linear_400_3594)"
        />
        <path
          id="leaf_57"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.6305 87.1982C29.2123 89.1593 30.4408 92.6642 30.746 96.6159C31.0418 100.445 30.3991 103.764 29.1857 105.621C27.4782 103.798 26.1337 100.242 25.8219 96.2051C25.5189 92.2827 26.2687 88.9149 27.6305 87.1982Z"
          fill="url(#paint56_linear_400_3594)"
        />
        <path
          id="leaf_58"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.0482 79.0651C26.02 80.8808 26.3166 83.8499 25.6781 87.0113C25.0594 90.0743 23.7127 92.5751 22.19 93.8268C21.0729 92.098 20.6836 89.0686 21.336 85.8392C21.9698 82.7013 23.424 80.1811 25.0482 79.0651Z"
          fill="url(#paint57_linear_400_3594)"
        />
        <path
          id="leaf_59"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6747 70.4522C23.2437 72.4315 22.9103 75.3967 21.6222 78.3536C20.3741 81.2185 18.5323 83.3809 16.7806 84.2848C16.0514 82.3601 16.3069 79.3164 17.6228 76.2959C18.9014 73.3609 20.8525 71.2022 22.6747 70.4522Z"
          fill="url(#paint58_linear_400_3594)"
        />
        <path
          id="leaf_60"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.5666 62.0204C22.5971 63.9768 21.6193 66.6675 19.8195 69.157C18.0756 71.5692 15.983 73.2075 14.2609 73.6936C14.0795 71.7554 15.0083 68.976 16.8469 66.4328C18.6333 63.9618 20.8195 62.3489 22.5666 62.0204Z"
          fill="url(#paint59_linear_400_3594)"
        />
        <path
          id="leaf_61"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.8119 53.2936C23.4271 55.1613 21.9341 57.5225 19.7068 59.5248C17.5487 61.4649 15.2247 62.5976 13.4944 62.7094C13.7327 60.8282 15.1982 58.3726 17.4734 56.3272C19.6842 54.3397 22.0913 53.2503 23.8119 53.2936Z"
          fill="url(#paint60_linear_400_3594)"
        />
        <path
          id="leaf_62"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.8721 46.675C24.3373 48.2503 22.7519 50.0685 20.539 51.4447C18.3949 52.7781 16.1817 53.3734 14.6073 53.1736C15.011 51.5612 16.581 49.6553 18.8415 48.2494C21.0379 46.8834 23.3218 46.3406 24.8721 46.675Z"
          fill="url(#paint61_linear_400_3594)"
        />
        <path
          id="leaf_63"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.806 40.7435C26.1073 42.1476 24.4118 43.684 22.1984 44.759C20.0541 45.8004 17.9436 46.1562 16.5243 45.8355C17.1076 44.3859 18.8005 42.7674 21.0615 41.6693C23.2583 40.6024 25.4267 40.301 26.806 40.7435Z"
          fill="url(#paint62_linear_400_3594)"
        />
        <path
          id="leaf_64"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.0111 35.6191C29.1503 36.7754 27.3837 37.9562 25.2377 38.6893C23.1591 39.3993 21.2286 39.517 20.0266 39.11C20.7929 37.9038 22.5714 36.6514 24.7635 35.9026C26.8929 35.1752 28.8654 35.1099 30.0111 35.6191Z"
          fill="url(#paint63_linear_400_3594)"
        />
        <path
          id="leaf_65"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.0497 31.7328C31.1979 32.684 29.5346 33.52 27.564 33.8788C25.6552 34.2264 23.921 34.0421 22.8754 33.4776C23.6464 32.4653 25.3254 31.5641 27.3384 31.1975C29.2938 30.8414 31.0618 31.0813 32.0497 31.7328Z"
          fill="url(#paint64_linear_400_3594)"
        />
        <path
          id="leaf_66"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.0672 27.2304C34.3855 28.0433 33.0269 28.7825 31.4004 29.1339C29.8242 29.4745 28.3786 29.3683 27.495 28.9276C28.108 28.0661 29.4778 27.2723 31.1393 26.9133C32.754 26.5645 34.2292 26.7158 35.0672 27.2304Z"
          fill="url(#paint65_linear_400_3594)"
        />
        <path
          id="leaf_67"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.0667 24.1801C37.3824 24.9921 36.0123 25.6571 34.368 25.876C32.7749 26.0881 31.3108 25.8298 30.4132 25.2653C31.0275 24.3938 32.4087 23.6708 34.0883 23.4473C35.7203 23.23 37.2147 23.5402 38.0667 24.1801Z"
          fill="url(#paint66_linear_400_3594)"
        />
        <path
          id="leaf_68"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.8555 21.7545C40.2009 22.3072 39.0209 22.6498 37.6836 22.6004C36.3878 22.5525 35.2586 22.148 34.6215 21.5771C35.2285 20.9676 36.4251 20.5804 37.7912 20.6309C39.1186 20.68 40.2649 21.1298 40.8555 21.7545Z"
          fill="url(#paint67_linear_400_3594)"
        />
        <path
          id="leaf_69"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.1455 18.8397C42.569 19.2722 41.5551 19.5694 40.4231 19.583C39.3263 19.5961 38.3847 19.3399 37.8666 18.9425C38.405 18.4698 39.4347 18.1391 40.5911 18.1253C41.7146 18.1118 42.6689 18.4008 43.1455 18.8397Z"
          fill="url(#paint68_linear_400_3594)"
        />
        <path
          id="leaf_70"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.1192 16.4442C44.7456 16.9017 43.9843 17.2728 43.0666 17.3882C42.1793 17.4997 41.3616 17.3451 40.8602 17.02C41.1938 16.5283 41.9609 16.1241 42.8987 16.0062C43.8081 15.8919 44.6433 16.0763 45.1192 16.4442Z"
          fill="url(#paint69_linear_400_3594)"
        />
        <path
          id="leaf_71"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.1192 14.4442C47.7456 14.9017 46.9843 15.2728 46.0666 15.3882C45.1793 15.4997 44.3616 15.3451 43.8602 15.02C44.1938 14.5283 44.9609 14.1241 45.8987 14.0062C46.8081 13.8919 47.6433 14.0763 48.1192 14.4442Z"
          fill="url(#paint70_linear_400_3594)"
        />
        <path
          id="leaf_72"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M55.8806 11.7657C55.1498 12.5469 53.7343 13.2231 52.071 13.4967C50.4626 13.7613 49.0139 13.5849 48.1557 13.1011C48.8189 12.2678 50.2494 11.537 51.9489 11.2574C53.5974 10.9862 55.0736 11.2096 55.8806 11.7657Z"
          fill="url(#paint71_linear_400_3594)"
        />
      </g>
      <g id="stars">
        <path
          id="star-small"
          d="M80.9689 14.3646C81.3941 14.1619 81.8381 14.6059 81.6354 15.0311L80.3226 17.7848C80.2577 17.9209 80.2577 18.0791 80.3226 18.2152L81.6354 20.9689C81.8381 21.3941 81.3941 21.8381 80.9689 21.6354L78.2152 20.3226C78.0791 20.2577 77.9209 20.2577 77.7848 20.3226L75.0311 21.6354C74.6059 21.8381 74.1619 21.3941 74.3646 20.9689L75.6774 18.2152C75.7423 18.0791 75.7423 17.9209 75.6774 17.7848L74.3646 15.0311C74.1619 14.6059 74.6059 14.1619 75.0311 14.3646L77.7848 15.6774C77.9209 15.7423 78.0791 15.7423 78.2152 15.6774L80.9689 14.3646Z"
          fill="#939393"
        />
        <path
          id="star-big"
          d="M69.1892 1.31997C69.3471 0.877319 69.9731 0.877318 70.131 1.31997L71.9804 6.50358C72.0311 6.64559 72.1431 6.75716 72.2853 6.80721L77.4946 8.64042C77.94 8.79715 77.94 9.42699 77.4946 9.58372L72.2853 11.4169C72.1431 11.467 72.0311 11.5785 71.9804 11.7206L70.131 16.9042C69.9731 17.3468 69.3471 17.3468 69.1892 16.9042L67.3398 11.7206C67.2892 11.5785 67.1771 11.467 67.0349 11.4169L61.8256 9.58372C61.3802 9.42699 61.3802 8.79715 61.8256 8.64042L67.0349 6.80721C67.1771 6.75716 67.2892 6.64559 67.3398 6.50358L69.1892 1.31997Z"
          fill="#939393"
        />
      </g>
    </g>
  </svg>
);

export default LogoCrown;
