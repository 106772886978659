export const WolfLogo = () => (
  <svg
    className="wolf-logo"
    width="85"
    height="135"
    viewBox="0 0 85 135"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="gradient1"
        x1="23"
        y1="0"
        x2="35.9874"
        y2="127.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.31138" stop-color="var(--gradient-start, #BABABA)" />
        <stop
          offset="1"
          stop-color="var(--gradient-end, #BABABA)"
          stop-opacity="1"
        />
      </linearGradient>
    </defs>
    <g id="wolf">
      <path
        id="wolf-fill"
        d="M19.6394 19.8219C19.6394 19.8219 15.2247 16.7371 14.8981 16.4895C14.5715 16.2418 12.482 16.332 12.482 16.332L11.6844 22.1184C11.6844 22.1184 27.2003 36.2849 18.1078 54.3295C18.1078 54.3295 19.7952 54.2365 20.3363 53.4953C20.3447 53.4843 20.3521 53.4731 20.3596 53.461C20.8729 52.6882 22.0491 70.2058 9.49391 78.5081C9.49391 78.5081 11.4573 78.404 12.6071 77.5136C12.6071 77.5136 7.46736 88.08 9.06351 93.9302C9.06351 93.9302 9.45946 90.762 13.4873 89.2818C13.4873 89.2818 6.95429 97.2593 13.5294 107.232C13.5294 107.232 12.3989 103.966 15.5559 101.633C15.5559 101.633 19.1828 109.499 15.0181 117.169C15.0181 117.169 20.0318 116.067 19.7587 112.248C19.7587 112.248 21.3043 118.888 15.3762 125.673C15.3762 125.673 18.3498 125.705 21.0541 123.417C21.0541 123.417 20.8517 128.104 25.4089 129.828C25.4089 129.828 21.3227 123.902 28.6101 120.04C28.6101 120.04 27.8154 123.351 31.1343 124.978C31.1343 124.978 34.9503 117.27 40.7172 114.778C40.7172 114.778 48.3564 112.755 49.4931 111.069C49.4931 111.069 51.1367 114.326 52.0424 115.153C52.0424 115.153 52.3811 105.192 61.9867 99.9787C61.9867 99.9787 71.6235 94.1564 70.3527 83.356C70.3527 83.356 73.6969 85.0462 73.0961 89.8757C73.0961 89.8757 80.8644 77.4383 68.1387 68.1146L69.317 66.952C69.317 66.952 77.8778 67.4388 78.7012 64.7723C78.7012 64.7723 66.9631 56.7123 65.9978 53.4033L59.2899 43.1732L57.5542 40.1819L53.3104 33.0185C53.3104 33.0185 49.3833 27.1106 44.88 24.8721C44.88 24.8721 36.5398 20.774 32.0859 12.5472C32.0859 12.5472 27.2831 3.97833 25.2502 2.60693C25.2502 2.60693 24.1427 1.26079 23.3232 0.987041C23.269 0.969563 23.2165 0.956219 23.1665 0.948089C22.3404 0.817223 20.8582 1.67315 20.7056 2.66538L20.1574 4.77131L18.2197 7.3833C18.2197 7.3833 17.2776 13.3419 19.6394 19.8219Z"
        fill="url(#gradient1)"
      />
    </g>
  </svg>
);

export default WolfLogo;
