import React, { useState, useEffect } from "react";

const DiagonalLines = () => {
  const [numSlashes, setNumSlashes] = useState(0);

  const updateNumSlashes = () => {
    // Assuming each ".slash" div is 30px wide
    const slashWidth = 30;
    const windowWidth = window.innerWidth;
    const newNumSlashes = Math.ceil(windowWidth / slashWidth) + 5;
    setNumSlashes(newNumSlashes);
  };

  useEffect(() => {
    updateNumSlashes();
    window.addEventListener("resize", updateNumSlashes);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", updateNumSlashes);
  }, []);

  return (
    <div className="Diagonal-lines">
      {Array.from({ length: numSlashes }, (_, index) => (
        <div key={index} className="slash"></div>
      ))}
    </div>
  );
};

export default DiagonalLines;
