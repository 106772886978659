import React from "react";
import ResumeSection from "./ResumeSection";

const Experience = () => {
  return (
    <div className="experience">
      <ResumeSection
        years="2021/2022/2023"
        title="ramp inc. ° principal designer"
        description="the first ic designer. established a design team of 23 excellent people.
        conceived of, designed, and helped build ramp's mobile apps"
        table="tableRamp"
      />
      <ResumeSection
        years="2018/2019/2020"
        title="squarespace ° staff designer"
        description="led the design of the company’s core suite of mobile apps; squarespace, commerce, and analytics. taught designers how to code."
        table="tableSqsp"
      />
      <ResumeSection
        years="2018/2019"
        title="hodinkee ° design director"
        description="managed a team of designers and lead the design of the company's web presences. creative director for the 10th anniversary exhibition. "
        table="tableDink"
      />
      <ResumeSection
        years="2017/2018"
        title="instagram ° senior designer"
        description="designed comments, feed interactions, and anti-bullying features. my time here wasn't long, but my contributions had a lasting impact."
        table="tableInsta"
      />
      <ResumeSection
        years="2014/2015/2016"
        title="facebook ° senior designer"
        description="founding designer of the social good team. lead the design of the most successful fundraising platform in history. >$6b raised as of 2024."
        table="tableFb"
      />
      <ResumeSection
        years="2011/2012/2013"
        title="spotify ° product designer"
        description="one of the first designers at the company. I designed the discover feed, radio, and the mobile free tier — the #1 free on-demand mobile streaming service in the world."
        table="tableSpot"
      />
    </div>
  );
};
export default Experience;
