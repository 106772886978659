import LogoCrown from "./LogoCrown";
import WolfLogo from "./WolfLogo";

const Header = () => {
  return (
    <header className="App-header">
      <LogoCrown />
      <WolfLogo />
    </header>
  );
};

export default Header;
