const Project = ({
  eyebrow,
  name,
  images,
  showCircle,
  circleText,
  desc,
  links,
}) => {
  return (
    <div className="Project">
      <div className="Project-body">
        {showCircle && (
          <svg
            className="circle-text"
            width="92"
            height="92"
            viewBox="0 0 56 56"
          >
            <path
              id="circlePath"
              fill="none"
              d="M28,4 a24,24 0 1,0 0,48 a24,24 0 1,0 0,-48"
            />
            <text>
              <textPath
                href="#circlePath"
                startOffset="50%"
                textAnchor="middle"
                style={{ fontSize: "7px" }}
              >
                {circleText}
              </textPath>
            </text>
          </svg>
        )}
        <h3 className="eyebrow">{eyebrow}</h3>
        <h3 className="Project-title">{name}</h3>
        <div className="Project-description">{desc}</div>
        {/* <div className="Project-links">
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
      <div className="Project-images">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`image #${index + 1} of ${name}`} />
        ))}
      </div>
    </div>
  );
};

export default Project;
