import { useEffect } from "react";
import React from "react";
import SvgName from "./SvgName";
import Stack from "./Stack";

const Footer = () => {
  useEffect(() => {
    function applyRandomAnimation() {
      const circles = document.querySelectorAll(".svg-loading circle");
      const maxRow = Math.max(
        ...Array.from(circles).map((circle) =>
          Math.floor(circle.getAttribute("cy") / 5)
        )
      );
      const animateFromBottom = Math.random() < 0.5;
      const colors = [
        "rgb(195, 78, 78)",
        "rgb(205, 159, 75",
        "rgb(179, 179, 74)",
        "rgb(91, 176, 91)",
        "rgb(108, 108, 230)",
        "rgb(176, 113, 221)",
        "violet",
      ];
      const originalColor = "var(--font-color-primary)"; // Define the original color

      circles.forEach((circle) => {
        circle.style.animation = "none";
        void circle.offsetHeight; // Force reflow

        const row = Math.floor(circle.getAttribute("cy") / 5);
        const adjustedRow = animateFromBottom ? maxRow - row : row;
        const randomIterations = Math.floor(Math.random() * 5) + 1;

        // Randomly decide whether to change the color of this circle
        if (Math.random() < 0.5) {
          // Adjust this value to control how many circles change color
          const randomColor = colors[Math.floor(Math.random() * colors.length)];
          circle.style.fill = randomColor;

          // Set a timeout to revert the color back to the original after the animation
          setTimeout(() => {
            circle.style.fill = originalColor;
          }, (adjustedRow * 0.1 + randomIterations * 0.5) * 500); // Duration based on delay and animation duration
        }

        setTimeout(() => {
          circle.style.setProperty("--row", adjustedRow.toString());
          circle.style.animation = `fadeIn 0.5s ease forwards ${
            adjustedRow * 0.1
          }s`;
          circle.style.animationIterationCount = randomIterations;
        }, 0);
      });
    }

    // Initial application
    applyRandomAnimation();

    // Re-apply the animation every couple of seconds
    const intervalId = setInterval(() => {
      applyRandomAnimation();
    }, 5000); // Adjust the interval as needed

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // const gitBranch = process.env.REACT_APP_GIT_BRANCH;
  const buildId = process.env.VERCEL_GIT_COMMIT_SHA || " 646b-hi-mom";

  return (
    <footer className="App-footer">
      <Stack />
      <SvgName />
      {/* <img src={sitDown} alt="photo from a presentation" />
      <img src={crossHatch} className="cross-hatch" alt="cross hatch graphic" /> */}

      <div className="conclusion">
        <a
          href="http://linkedin.com/in/eric-eriksson-7b4b0220"
          alt="link to eric eriksson's linkedin profile"
        >
          <div className="ext-button">linkedin</div>
        </a>
        <a
          href="https://github.com/ericpron"
          alt="link to eric eriksson's github profile"
        >
          <div className="ext-button">github</div>
        </a>
        <a
          href="https://read.cv/eric"
          alt="link to eric eriksson's read.cv profile"
        >
          <div className="ext-button">read.cv</div>
        </a>
      </div>
      <div className="version-container">
        <p className="version-footer">
          version ~ 7.4 since 2007 · built in vanila react by eric eriksson ·
          hosted on vercel · build
          {buildId}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
